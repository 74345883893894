import Vue from 'vue'
import Router from 'vue-router'
 
//组件模块
import Index from '@/components/index'

Vue.use(Router)
 
const router = new Router({
  mode: 'history',
  routes: [
    { path: '/', name: 'Index', component: Index }
  ]
})

export default router