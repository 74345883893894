<template>
  <div class="header">
    <footer>
      <div class="info-container">
        <div class="address-box">
          <div class="address-option">
            <img class="address-icon" src="@/assets/address.png" />
            <div>地址</div>
          </div>
          <div style="opacity: 0.8;">深圳市南山区前海国际人才港 B 栋 1602</div>
        </div>

        <div class="weixin-container">
          <div class="weixin-item">
            <img class="weixin-icon" src="https://ac-static-2022.oss-cn-shenzhen.aliyuncs.com/guanwang/assets/gzh.jpg" />
            <div>官方公众号</div>
          </div>

          <div class="weixin-item" style="margin-left: 20px;">
            <img class="weixin-icon" src="https://ac-static-2022.oss-cn-shenzhen.aliyuncs.com/guanwang/assets/lin.png" />
            <div>林老师</div>
          </div>

          <div class="weixin-item" style="margin-left: 20px;">
            <img class="weixin-icon" src="https://ac-static-2022.oss-cn-shenzhen.aliyuncs.com/guanwang/assets/yang.png" />
            <div>杨老师</div>
          </div>
        </div>
      </div>
      <div class="beian-container">
        版权所有©深圳市爱创科技教育有限公司
        <div>
          <a class="text_lBGRw" href="http://beian.miit.gov.cn/" target="_blank" rel="noreferrer" one-link-mark="yes">粤ICP备18072886号</a>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
export default {
  name: 'Header',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
 footer {
  width: 100vw;
  /* border: 1px solid red; */
  background: #1165f8;
  box-sizing: border-box;
  margin-top: 40px;
  color: #fff;
}

.beian-container {
  width: 100vw;
  background: #1e3e7d;
  padding: 10px 0;
  box-sizing: border-box;
  font-size: 0.8rem;
  padding: 10px;
}

.beian-container a {
  color: #fff;
  text-decoration: none;
}

.info-container {
  padding: 40px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.address-box {
  width: 50%;
  display: flex;
  flex-direction: column;
  text-align: left;
  /* border: 1px solid #fff; */
  margin-bottom: 30px;
}

.address-option {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.address-icon {
  width: 20px;
  height: 20px;
}

.weixin-container {
  display: flex;
}

.weixin-item {
  opacity: 0.8;
}

.weixin-icon {
  width: 80px;
  height: 80px;
}
</style>
