<template>
  <div id="app">
    <Header class="pc" />
    <HeaderMobile class="mobile" />
    <main>
      <router-view />
    </main>
    <Footer class="pc" />
    <FooterMobile class="mobile" />
  </div>
</template>

<script>
import Header from '@/components/header.vue'
import Footer from '@/components/footer.vue'
import HeaderMobile from './components/headerMobile.vue'
import FooterMobile from './components/footerMobile.vue'

export default {
  name: 'App',
  components: {
    Header,
    Footer,
    HeaderMobile,
    FooterMobile
  },
  computed: {
  },
  created () {
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  display: flex;
  flex-direction: column;
  align-items: center;
}

* {
  box-sizing: border-box;
}

body, html {
  margin: 0;
  padding: 0;
}

main {
  min-height: calc(100vh - 130px);
  box-sizing: border-box;
  margin-top: 60px;
}

.logo {
  width: 40px;
  height: 40px;
}

@media (max-width: 767px) {
  .pc {
    display: none !important;
  }
}

@media (min-width: 768px) {
  .mobile {
    display: none !important;
  }
}
</style>
