<template>
  <div class="container">
    <div>
      <img class="cover" src="https://ac-static-2022.oss-cn-shenzhen.aliyuncs.com/guanwang/assets/cover.jpeg" />
    </div>

    <div class="new-tip-container" id="news">
      <div style="color: #333; font-size: 26px; font-weight: bold;">资讯动态</div>
      <div>了解我们的最新动态</div>
    </div>

    <div class="news-container">
      <div class="news-left">
        <img class="news-img" src="https://ac-static-2022.oss-cn-shenzhen.aliyuncs.com/guanwang/assets/news.jpg" />
        <div class="news-first-title" @click="openNews('https://mp.weixin.qq.com/s/AX93-suoE3EVEoXWdRvTBg')">爱创携"劳动创新创造解决方案"亮相第58・59届中国高等教育博览会</div>
      </div>
      <div class="news-right">
        <div class="news-item" v-for="item of news" :key="item.url">
         <a :href="item.url" target="_blank">
          <div class="news-title-box">
            <div>{{item.title}}</div>
            <div>{{item.date}}</div>
          </div>
        </a>
        </div>
      </div>
    </div>


    <div class="new-tip-container" id="event">
      <div style="color: #333; font-size: 26px; font-weight: bold;">白名单赛事</div>
      <div>国家白名单赛事资讯和介绍</div>
    </div>

    <div class="news-container">
      <div class="news-left">
        <img class="news-img" src="https://ac-static-2022.oss-cn-shenzhen.aliyuncs.com/guanwang/assets/event.png" />
        <div class="news-first-title" @click="showQRCode">2024-2025学年全国青少年劳动技能与智能设计大赛—AILD智能设计《超级工匠—车间抢险》赛项解说</div>
      </div>
      <div class="news-right">
        <div class="news-item" v-for="item of event_news" :key="item.url">
         <a :href="item.url" target="_blank">
          <div class="news-title-box">
            <div>{{item.title}}</div>
            <div>{{item.date}}</div>
          </div>
        </a>
        </div>
      </div>
    </div>

    <div class="new-tip-container" id="service">
      <div style="color: #333; font-size: 26px; font-weight: bold;">主要业务</div>
      <div>致力于做最适合中国的创新教育</div>
    </div>

    <div class="yewu-container">
      <el-card class="box-card">
        <el-avatar shape="square" :size="300" fit="contain" src="https://ac-static-2022.oss-cn-shenzhen.aliyuncs.com/image/yewu1.jpg"></el-avatar>
        <!-- <img class="yewu-img" src="@/assets/yewu1.jpg" /> -->
        <div class="yewu-box">
          <div class="yewu-title">课程服务</div>
          <div class="yewu-content">为不同需求的客户提供定制化服务</div>
        </div>
      </el-card>

      <el-card class="box-card">
        <div>
          <el-avatar shape="square" :size="300" fit="contain" src="https://ac-static-2022.oss-cn-shenzhen.aliyuncs.com/image/yewu2.jpg"></el-avatar>
        </div>
        <div class="yewu-box">
          <div class="yewu-title">教室设计及装配方案</div>
          <div class="yewu-content">科技分享会授课模式，有效利用空间</div>
        </div>
      </el-card>

      <el-card class="box-card">
        <div>
          <el-avatar shape="square" :size="300" fit="contain" src="https://ac-static-2022.oss-cn-shenzhen.aliyuncs.com/image/yewu3.jpg"></el-avatar>
        </div>
        <div class="yewu-box">
          <div class="yewu-title">科技节承办</div>
          <div class="yewu-content">主题丰富，为学校提供定制化服务</div>
        </div>
      </el-card>
    </div>


    <div class="new-tip-container" id="product">
      <div style="color: #333; font-size: 26px; font-weight: bold;">明星产品</div>
      <div>自研独创硬件设备，权威专业教研服务</div>
    </div>

    <div class="chanping-container">
      <div class="chanping-head">
        <div class="chanping-tab">专利设备</div>
      </div>
      <div class="chanping-content">
        <div class="chanping-content-left">
          <div class="chanping-title">产品优势</div>
          <div class="chanping-description">为学校授课量身打造的安全高效的设备。已获得多项国家专利。</div>

          <div class="product-advantage" @click="showProductAdvantage">了解详情</div>
        </div>
        <div class="chanping-content-right">
          <div class="chanping-item">
            <el-avatar shape="square" :size="300" fit="contain" src="https://ac-static-2022.oss-cn-shenzhen.aliyuncs.com/image/changpin1.jpg"></el-avatar>
            <div>爱创安全创造台</div>
          </div>

          <div class="chanping-item">
            <el-avatar shape="square" :size="300" fit="contain" src="https://ac-static-2022.oss-cn-shenzhen.aliyuncs.com/image/changpin2.jpg"></el-avatar>
            <div>雾化水帘过滤系统</div>
          </div>
        </div>
      </div>
    </div>


    <div class="new-tip-container" id="case">
      <div style="color: #333; font-size: 26px; font-weight: bold;">特色案例</div>
      <div>为新时代劳动教育课程注入灵魂</div>
    </div>

    <div class="carousel-container">
      <el-carousel :interval="10000" type="card" height="300px">
        <el-carousel-item label="麒麟小学">
          <img class="carousel-img" src="https://ac-static-2022.oss-cn-shenzhen.aliyuncs.com/guanwang/assets/麒麟小学.jpg" />
        </el-carousel-item>

        <el-carousel-item label="桃源小学">
          <img class="carousel-img" src="https://ac-static-2022.oss-cn-shenzhen.aliyuncs.com/guanwang/assets/桃源小学.jpg" />
        </el-carousel-item>

        <el-carousel-item label="卓雅小学">
          <img class="carousel-img" src="https://ac-static-2022.oss-cn-shenzhen.aliyuncs.com/guanwang/assets/卓雅小学.jpg" />
        </el-carousel-item>
      </el-carousel>
    </div>

    <div class="new-tip-container" id="partner">
      <div style="color: #333; font-size: 26px; font-weight: bold;">合作伙伴</div>
      <div>为众多名校提供课程、科技活动承办服务</div>
    </div>

    <div class="partner-container">
      <div class="partner-box">
        <div class="partner-item">
          <img class="partner-logo" src="https://ac-static-2022.oss-cn-shenzhen.aliyuncs.com/guanwang/assets/1.svg" />
          <div class="partner-name">中国地质大学(北京)</div>
        </div>

        <div class="partner-item">
          <img class="partner-logo" src="https://ac-static-2022.oss-cn-shenzhen.aliyuncs.com/guanwang/assets/2.svg" />
          <div class="partner-name">山西省运城市盐湖区党群服务中心</div>
        </div>

        <div class="partner-item">
          <img class="partner-logo" src="https://ac-static-2022.oss-cn-shenzhen.aliyuncs.com/guanwang/assets/3.svg" />
          <div class="partner-name">深圳小学</div>
        </div>

        <div class="partner-item">
          <img class="partner-logo" src="https://ac-static-2022.oss-cn-shenzhen.aliyuncs.com/guanwang/assets/4.svg" />
          <div class="partner-name">深圳外国语学校（集团）宝安学校</div>
        </div>

        <div class="partner-item">
          <img class="partner-logo" src="https://ac-static-2022.oss-cn-shenzhen.aliyuncs.com/guanwang/assets/5.svg" />
          <div class="partner-name">深圳市福田区劳动技术教育中心</div>
        </div>

      </div>

      <div class="partner-box">
        <div class="partner-item">
          <img class="partner-logo" src="https://ac-static-2022.oss-cn-shenzhen.aliyuncs.com/guanwang/assets/6.svg" />
          <div class="partner-name">深圳高级中学集团东校区</div>
        </div>

        <div class="partner-item">
          <img class="partner-logo" src="https://ac-static-2022.oss-cn-shenzhen.aliyuncs.com/guanwang/assets/7.svg" />
          <div class="partner-name">深圳市南山外国语学校（集团）桃源中学</div>
        </div>

        <div class="partner-item">
          <img class="partner-logo" src="https://ac-static-2022.oss-cn-shenzhen.aliyuncs.com/guanwang/assets/8.svg" />
          <div class="partner-name">福田实验教育集团梅园小学</div>
        </div>

        <div class="partner-item">
          <img class="partner-logo" src="https://ac-static-2022.oss-cn-shenzhen.aliyuncs.com/guanwang/assets/9.svg" />
          <div class="partner-name">深圳市南山实验教育集团麒麟小学</div>
        </div>

        <div class="partner-item">
          <img class="partner-logo" src="https://ac-static-2022.oss-cn-shenzhen.aliyuncs.com/guanwang/assets/10.svg" />
          <div class="partner-name">深圳市海滨中学</div>
        </div>
      </div>

      <div class="partner-box">
        <div class="partner-item">
          <img class="partner-logo" src="https://ac-static-2022.oss-cn-shenzhen.aliyuncs.com/guanwang/assets/11.svg" />
          <div class="partner-name">荔园外国语小学</div>
        </div>

        <div class="partner-item">
          <img class="partner-logo" src="https://ac-static-2022.oss-cn-shenzhen.aliyuncs.com/guanwang/assets/12.svg" />
          <div class="partner-name">深圳市福田区百花小学</div>
        </div>

        <div class="partner-item">
          <img class="partner-logo" src="https://ac-static-2022.oss-cn-shenzhen.aliyuncs.com/guanwang/assets/13.svg" />
          <div class="partner-name">深圳市南山区桃源小学</div>
        </div>

        <div class="partner-item">
          <img class="partner-logo" src="https://ac-static-2022.oss-cn-shenzhen.aliyuncs.com/guanwang/assets/14.svg" />
          <div class="partner-name">深圳市鹏兴实验学校</div>
        </div>

        <div class="partner-item">
          <img class="partner-logo" src="https://ac-static-2022.oss-cn-shenzhen.aliyuncs.com/guanwang/assets/15.svg" />
          <div class="partner-name">深圳市卓雅小学</div>
        </div>
      </div>
    </div>

    <!-- 添加二维码弹窗 -->
    <el-dialog
      title="微信扫码观看视频"
      :visible.sync="dialogVisible"
      width="300px"
      center>
      <div style="text-align: center;">
        <img style="width: 200px; height: 200px;" src="https://ac-static-2022.oss-cn-shenzhen.aliyuncs.com/guanwang/assets/event_qr.png" />
      </div>
    </el-dialog>

    <!-- 添加合作联系表单 -->
    <div class="contact-container">
      <div class="contact-title">留下合作信息</div>
      <div class="contact-subtitle">填写以下信息，我们的商务团队会与您联系</div>
      <div class="contact-form">
        <el-form :model="contactForm" :rules="rules" ref="contactForm" label-width="100px">
          <el-form-item label="姓名/单位" prop="name">
            <el-input v-model="contactForm.name"></el-input>
          </el-form-item>
          <el-form-item label="手机号" prop="phone">
            <el-input v-model="contactForm.phone"></el-input>
          </el-form-item>
          <el-form-item label="微信" prop="wechat">
            <el-input v-model="contactForm.wechat"></el-input>
          </el-form-item>
          <el-form-item label="合作内容" prop="content">
            <el-input type="textarea" v-model="contactForm.content"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="submitForm" :disabled="isSubmitted">
              {{ isSubmitted ? '已提交' : '提交' }}
            </el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>

  </div>
</template>

<script>

export default {
  name: 'Header',
  data () {
    return {
      event_news: [{
        title: '【大赛通知】关于组织召开2024-2025学年AILD智能设计大赛全国说明会的通知',
        date: '2024-12-20',
        url: 'https://mp.weixin.qq.com/s/TXEh2FE4DemCs_ZD-qYKDQ'
      }, {
        title: '第五届（2024-2025学年）全国青少年科技教育成果展示大赛赛项及技术支持单位公告（第一批）',
        date: '2024-12-18',
        url: 'https://mp.weixin.qq.com/s/cHBYFLImpI0U3ig4lIoNAQ'
      }, {
        title: '第五届（2024-2025学年）全国青少年科技教育成果展示大赛省级区域赛公告（第一批）',
        date: '2024-12-18',
        url: 'https://mp.weixin.qq.com/s/wCeCDRb3L3AzFP7IkOvkMA'
      }],
      news: [{
        title: '我司被选为第五届（2024-2025学年）全国青少年科技教育成果展示大赛赛项及技术支持单位',
        date: '2024-12-18',
        url: 'https://mp.weixin.qq.com/s/cHBYFLImpI0U3ig4lIoNAQ'
      }, {
        title: '【做花灯 猜灯谜 抽扭蛋】和萌娃一同闹元宵',
        date: '2023-02-07',
        url: 'https://mp.weixin.qq.com/s/Axopk5j0XHroEe9aQr4QSA'
      }, {
        title: '直击创作大赛颁奖仪式，见证参赛选手们的成长',
        date: '2022-12-13',
        url: 'https://mp.weixin.qq.com/s/EEnOjWtcahzPsIYqIRQQOA'
      }],
      dialogVisible: false,
      isSubmitted: false,
      contactForm: {
        name: '',
        phone: '',
        wechat: '',
        content: ''
      },
      rules: {
        name: [{ required: true, message: '请输入姓名/单位', trigger: 'blur' }],
        phone: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          { pattern: /^1[3456789]\d{9}$/, message: '请输入正确的手机号', trigger: 'blur' }
        ],
        content: [{ required: true, message: '请输入合作内容', trigger: 'blur' }]
      }
    }
  },
  created() {
    this.isSubmitted = localStorage.getItem('contactSubmitted') === 'true'
  },
  props: {
    msg: String
  },
  components: {
  },
  methods: {
    showProductAdvantage () {
      window.open('https://mp.weixin.qq.com/s/O11ddKMhTOS2s5fMHzFzdA', "_blank")
    },

    openNews (url) {
      window.open(url, "_blank")
    },

    showQRCode() {
      this.dialogVisible = true
    },

    submitForm() {
      this.$refs.contactForm.validate(async (valid) => {
        if (valid) {
          try {
            const response = await fetch('https://api-v2.actuedu.com/shangwu/add', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json'
              },
              body: JSON.stringify(this.contactForm)
            });
            if (response.ok) {
              this.$message.success('提交成功');
              this.$refs.contactForm.resetFields();
              this.isSubmitted = true;
              localStorage.setItem('contactSubmitted', 'true');
            } else {
              this.$message.error('提交失败，请稍后重试');
            }
          } catch (error) {
            this.$message.error('提交失败，请稍后重试');
          }
        }
      });
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
header {
  width: 1024px;
  border: 1px solid red;
}

.cover {
  width: calc(100vw - 15px);
  height: 66.67vw;
  box-sizing: border-box;
  margin: auto;
}

.news-container {
  display: flex;
  margin: auto;
  width: 1024px;
  justify-content: center;
}

.news-left {
  width: 400px;
}

.news-img {
  width: 400px;
}

.news-right {
  margin: 0 20px;
  width: 500px;
}

.news-item {
  cursor: pointer;
  display: flex;
  margin: 5px 0;
  padding: 5px 0;
  width: 100%;
}

.news-title-box:hover {
  color:#1165f8;
}
.news-first-title {
  cursor: pointer;
}

.news-first-title:hover {
  color:#1165f8;
}

.news-title-box {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.news-title-box > div:first-child {
  flex: 1;
  margin-right: 15px;
  word-wrap: break-word;
  text-align: left;
}

.news-title-box > div:last-child {
  flex-shrink: 0;
  white-space: nowrap;
  min-width: 80px;
  text-align: right;
}

.news-item a {
  text-decoration: none;
  color: #333;
}

.yewu-container {
  width: 1024px;
  display: flex;
  margin: auto;
  justify-content: space-around;
}

.yewu-box {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 15px 10px;
  /* border: 1px solid green; */
}

.yewu-title {
  font-size: 18px;
  font-weight: bold;
  /* border: 1px solid green; */
}

.yewu-content {
  font-size: 15px;
  /* border: 1px solid green; */
  text-align: left;
}


.box-card {
  width: 300px;
  box-sizing: border-box;
  padding: 0 !important;
}

::v-deep .el-card__body,
::v-deep .el-main {
  padding: 0;
}

.el-main {
  padding: 0;
}

.yewu-img {
  width: 300px;
  height: 225px;
}

.chanping-container {
  width: 980px;
  margin: auto;
  /* border: 1px solid red; */
}

.chanping-head {
  display: flex;
  width: 100%;
  margin: auto;
  border-bottom: #A4A4A4 1px solid;
  padding: 10px;
}

.chanping-content {
  display: flex;
  padding: 40px 0;
}

.chanping-content-left {
  display: flex;
  flex-direction: column;
  text-align: left;
  width: 30%;
  border-right: #A4A4A4 0.5px solid;
  padding: 0 20px;
}

.chanping-content-right {
  display: flex;
  justify-content: space-around;
  width: 70%;
}

.chanping-item {
  margin: 10px;
}

.chanping-title {
  font-size: 19px;
  font-weight: bold;
}

.chanping-description {
  font-size: 15px;
  margin-top: 10px;
}

.chanping-tab {
  color: #1165f8;
  border-bottom: 3px solid #1165f8;
}

.product-advantage {
  background: #1165f8;
  color: #fff;
  width: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  padding: 4px;
  margin-top: 20px;
  cursor: pointer;
}

.new-tip-container {
  height: 150px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.partner-container {
  display: flex;
  flex-wrap: wrap;
  width: 1024px;
  margin: auto;
  /* border: 1px salmon solid; */
}

.partner-logo {
  width: 60px;
  height: 60px;
}

.partner-name {
  margin-top: 4px;
}

.partner-box {
  width: 1024px;
  display: flex;
  justify-content: space-between;
  /* border: 1px solid green; */
  font-size: 14px;
}

.partner-item {
  width: calc(1024px / 5);
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
  padding: 10px 0;
  /* border: 1px solid red; */
}

.carousel-container {
  width: 1024px;
  margin: auto;
}

.carousel-img {
  height: 300px;
}

.contact-container {
  width: 1024px;
  margin: 40px auto;
  padding: 20px;
  background: #f5f7fa;
  border-radius: 8px;
}

.contact-title {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
  color: #333;
}

.contact-subtitle {
  font-size: 14px;
  color: #666;
  margin-bottom: 20px;
  color: #333;
}

.contact-form {
  width: 500px;
  margin: 0 auto;
}
</style>
