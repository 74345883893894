<template>
  <div class="header-container">
    <header class="header">
      <div class="title-box" @click="goToHome">
        <img class="logo" src="https://ac-static-2022.oss-cn-shenzhen.aliyuncs.com/guanwang/assets/logo.jpeg" />
        <h1 class="title">深圳市爱创科技教育有限公司</h1>
      </div>
      <nav>
        <a href="#news">
          <div class="nav-item">资讯动态</div>
        </a>
        <a href="#event">
          <div class="nav-item">白名单赛事</div>
        </a>
        <a href="#service">
          <div class="nav-item">主要业务</div>
        </a>
        <a href="#product">
          <div class="nav-item">明星产品</div>
        </a>
        <a href="#case">
          <div class="nav-item">特色案例</div>
        </a>
        <a href="#partner">
          <div class="nav-item">合作伙伴</div>
        </a>
      </nav>
    </header>
  </div>
</template>

<script>
export default {
  name: 'Header',
  props: {
    msg: String
  },
  methods: {
    goToHome() {
      location.href = '/'
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .header-container {
    position: fixed;
    width: 100vw;
    /* border: 1px solid blue; */
    z-index: 100;
    display: flex;
    justify-content: center;
    background: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }

  header {
    width: 1024px;
    /* border: 1px solid red; */
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .title-box {
    display: flex;
    align-items: center;
    cursor: pointer;
    /* border: 1px solid green; */
  }

  nav {
    display: flex;
    align-items: center;
  }

  .nav-item {
    margin: 0 20px;
    cursor: pointer;
  }

  .title {
    font-size: 16px;
    margin-left: 8px;
  }

  a {
    text-decoration: none;
    color: #000;
  }
</style>
