<template>
  <div>
    <Home class="pc" />
    <HomeMobile class="mobile" />
  </div>
</template>
<script>
import HomeMobile from '@/components/homeMobile.vue'
import Home from '@/components/home.vue'

export default {
  name: 'Index',
  props: {
    msg: String
  },
  components: {
    Home,
    HomeMobile
  },
  methods: {
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
